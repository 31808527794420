<template>
	<v-tabs :background-color="$vuetify.theme.dark ? '' : 'primary'" vertical slider-size="5">
		<v-tab
			v-for="link in menu.filter(link => !link.acceso || $root.acceso(link.acceso))"
			exact-active-class="active-tab"
			style="height: 40px"
			class="rounded-r-pill mr-1"
			:to="link.url"
			:key="link.url"
		>
			<v-list-item-icon class="my-auto">
				<v-icon color="white" v-text="link.icon" />
			</v-list-item-icon>
			<v-list-item-content class="text-start white--text" style="font-size: 0.7rem">
				<div class="d-flex">{{ link.texto }}</div>
			</v-list-item-content>
		</v-tab>
	</v-tabs>
</template>

<script>
export default {
	props: {
		menu: Array,
	}
}
</script>